<template>
    <div>
        <el-card id="mapCard" class="box-card" style="width: 96%;height:85vh;margin: 15px auto;z-index: 10;">
            <div id="mapDiv"></div>
        </el-card>

        <transition name="slide-fade">
            <div v-show="toolbarShow" class="transition-box" v-loading="toolbarLoading">
                <i class="el-icon-help" @click="toolbarShow = false"
                    style="font-size: 24px;color: #e2d849;float: right;margin: 10px 10px 0 0;" />
                <el-tree :data="toolbarList" show-checkbox node-key="id" :default-checked-keys="[10000]"
                    style="width: 80%;height: 100%;padding: 10px 0;margin-left: 2px;" @check="handleMarker">
                </el-tree>
            </div>
        </transition>

        <i class="el-icon-setting" @click="toolbarShow = true"
            style="font-size: 24px;position: fixed;top: 110px;right: 60px;z-index: 998;color: #e2d849;"></i>

        <el-dialog title="设备管理" width="60%" :visible.sync="deviceManagementVisible">
            <el-table :data="deviceTypeTable" row-key="deviceTypeId" style="width: 100%">
                <el-table-column type="expand" align="center" width="50">
                    <template slot="header">
                        <el-button type="text" @click="deviceTypeVisible = true">添加</el-button>
                    </template>
                    <template slot-scope="scope">
                        <el-table :data="scope.row.thisDeviceList" style="width: 100%">
                            <el-table-column prop="deviceCode" label="设备名称" width="200">
                            </el-table-column>
                            <el-table-column prop="name" label="设备归属" width="200">
                                <template slot-scope="scope">
                                    <el-popover title="土地信息" placement="right" width="200" trigger="hover">
                                        <p style="font-size:16px;margin:6px 0;"><b>土地面积：</b>{{scope.row.area}}亩</p>
                                        <p style="font-size:16px;margin:6px 0;"><b>土地类型：</b>{{scope.row.landType}}</p>
                                        <p style="font-size:16px;margin:6px 0;"><b>所属区域：</b>{{scope.row.region}}</p>
                                        <p style="font-size:16px;margin:6px 0;"><b>测绘情况：</b>{{scope.row.isMapping}}</p>
                                        <span slot="reference">{{scope.row.name}}&nbsp;<i
                                                class="el-icon-info"></i></span>
                                    </el-popover>
                                </template>
                            </el-table-column>
                            <el-table-column prop="deviceDescription" label="设备描述">
                            </el-table-column>
                            <el-table-column property="operation" label="操作" width="80">
                                <template slot-scope="scope">
                                    <el-popconfirm title="是否解除此土地与该设备间的关系？" @confirm="deleteLandDevice(scope)">
                                        <el-button type="text" slot="reference">删除</el-button>
                                    </el-popconfirm>
                                </template>
                            </el-table-column>
                        </el-table>
                    </template>
                </el-table-column>
                <el-table-column label="设备类型" prop="deviceType" width="200">
                </el-table-column>
                <el-table-column label="设备图标" prop="deviceIcon">
                    <template slot-scope="scope">
                        <el-image style="width: 24px; height: 24px"
                            :src="'http://110.40.132.120:8080' + scope.row.deviceIcon">
                        </el-image>
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button type="text" @click="editDeviceType(scope.row)">编辑</el-button>
                        <el-popconfirm confirm-button-text='移除' cancel-button-text='取消' icon="el-icon-info"
                            icon-color="red" title="是否移除此设备类型？" @confirm="deleteDeviceType(scope.row)">
                            <el-button slot="reference" type="text" style="margin-left: 10px;">删除</el-button>
                        </el-popconfirm>
                    </template>
                </el-table-column>
            </el-table>
            <span slot="footer" class="dialog-footer">
                <el-button @click="deviceManagementVisible = false">返 回</el-button>
            </span>
        </el-dialog>

        <el-dialog title="设备维护" :visible.sync="dialogVisible" width="50%">
            <el-table :data="landData">
                <el-table-column property="name" label="土地名称" width="120">
                    <template slot="header">
                        <el-button type="text" @click="deviceVisible = true">添加</el-button>
                    </template>
                    <template slot-scope="scope">
                        <el-popover title="土地信息" placement="right" width="200" trigger="hover">
                            <p style="font-size:16px;margin:6px 0;"><b>土地面积：</b>{{scope.row.area}}亩</p>
                            <p style="font-size:16px;margin:6px 0;"><b>土地类型：</b>{{scope.row.landType}}</p>
                            <p style="font-size:16px;margin:6px 0;"><b>所属区域：</b>{{scope.row.region}}</p>
                            <p style="font-size:16px;margin:6px 0;"><b>测绘情况：</b>{{scope.row.isMapping}}</p>
                            <span slot="reference">{{scope.row.name}}&nbsp;<i class="el-icon-info"></i></span>
                        </el-popover>
                    </template>
                </el-table-column>
                <el-table-column property="type" label="设备类型" width="150"></el-table-column>
                <el-table-column property="description" label="设备描述" width="250"></el-table-column>
                <el-table-column property="operation" label="操作">
                    <template slot-scope="scope">
                        <el-popconfirm title="是否解除此土地与该设备间的关系？" @confirm="deleteLandDevice(scope)">
                            <el-button type="text" slot="reference">删除</el-button>
                        </el-popconfirm>
                    </template>
                </el-table-column>
            </el-table>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">返 回</el-button>
            </span>
        </el-dialog>

        <el-dialog title="设备类型维护" :visible.sync="deviceTypeVisible" width="40%">
            <el-form :model="deviceTypeForm">
                <el-form-item label="类型名称" label-width="120px">
                    <el-input v-model="deviceTypeForm.name" autocomplete="off" style="width: 80%;padding-left: 20px;">
                    </el-input>
                </el-form-item>
                <el-form-item label="设备图标" label-width="120px">
                    <el-upload class="upload-demo" action="#" accept="image/png" :on-change="handleSelectFile"
                        :before-remove="beforeRemove" :limit="1" :on-exceed="handleExceed" :auto-upload="false">
                        <el-button size="small" type="primary" style="margin-left: 20px;">点击上传</el-button>
                        <div slot="tip" class="el-upload__tip" style="margin-left: 20px;">只能上传png文件，且不超过500kb</div>
                    </el-upload>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="deviceTypeVisible = false">取 消</el-button>
                <el-button type="primary" @click="submitUploadIcon()">提 交</el-button>
            </div>
        </el-dialog>

        <el-dialog title="设备类型维护" :visible.sync="deviceVisible" width="40%">
            <el-form :model="deviceForm">
                <el-form-item label="设备名称" label-width="120px">
                    <el-select v-model="deviceForm.type" placeholder="请选择" style="padding-left: 20px;width: 40%;">
                        <el-option v-for="item in deviceForm.typeList" :key="item.deviceTypeId" :label="item.deviceType"
                            :value="item.deviceTypeId">
                        </el-option>
                    </el-select>
                    <el-input v-model="deviceForm.name" autocomplete="off" style="width: 40%;"></el-input>
                </el-form-item>
                <el-form-item label="设备坐标" label-width="120px">
                    <el-input v-model="deviceForm.lng" autocomplete="off" style="width: 40%;padding-left: 20px;"
                        disabled></el-input>
                    <el-input v-model="deviceForm.lat" autocomplete="off" style="width: 40%;" disabled></el-input>
                </el-form-item>
                <el-form-item label="土地信息" label-width="120px">
                    <el-popover title="土地信息" placement="right" width="200" trigger="hover">
                        <p style="font-size:16px;margin:6px 0;"><b>土地面积：</b>{{deviceForm.land.area}}亩</p>
                        <p style="font-size:16px;margin:6px 0;"><b>土地类型：</b>{{deviceForm.land.landType}}</p>
                        <p style="font-size:16px;margin:6px 0;"><b>所属区域：</b>{{deviceForm.land.region}}</p>
                        <p style="font-size:16px;margin:6px 0;"><b>测绘情况：</b>{{deviceForm.land.isMapping}}</p>
                        <span slot="reference"
                            style="margin-left: 20px;line-height: 30px;">{{deviceForm.land.name}}&nbsp;<i
                                class="el-icon-info"></i></span>
                    </el-popover>
                </el-form-item>
                <el-form-item label="设备描述" label-width="120px">
                    <el-input v-model="deviceForm.detail" autocomplete="off" style="width: 80%;padding-left: 20px;">
                    </el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="deviceVisible = false">取 消</el-button>
                <el-button type="primary" @click="submitInsertDevice()">提 交</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    var markers = [];

    export default {
        data() {
            return {
                token: '',
                lands: [],
                deviceList: [],
                PI: 3.14159265358979324,
                dialogVisible: false,
                deviceManagementVisible: false,
                landData: [],
                deviceTypeTable: [],
                deviceTable: [],
                deviceTypeVisible: false,
                deviceTypeForm: {
                    id: -1,
                    name: '',
                    file: {},
                },
                deviceVisible: false,
                deviceForm: {
                    typeList: [],
                    landId: '',
                    type: '',
                    name: '',
                    lng: 0,
                    lat: 0,
                    land: {
                        id: '',
                        name: '',
                        area: '',
                        landType: '',
                        region: '',
                        isMapping: '',
                    },
                    detail: '',
                },
                toolbarShow: true,
                toolbarList: [],
                toolbarLoading: true,
            };
        },
        created() {
            this.initmap();
        },
        methods: {
            init() {
                return new Promise((resolve, reject) => {
                    if (window.T) {
                        resolve(window.T);
                        reject('error');
                    }
                })
            },
            async initmap() {
                this.init().then(T => {
                    var that = this;
                    var map;
                    var zoom = 16;
                    map = new T.Map("mapDiv");
                    map.centerAndZoom(new T.LngLat(86.049988, 44.313876), zoom);
                    map.enableScrollWheelZoom();
                    var imageURL = "http://t0.tianditu.gov.cn/img_w/wmts?" +
                        "SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=img&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles" +
                        "&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=f65447545a8140e4c25f0b55b66d53b6";
                    var lay = new T.TileLayer(imageURL, { minZoom: 1, maxZoom: 18 });
                    map.addLayer(lay);
                    map.addEventListener("click", e => {
                        that.deviceForm.lng = e.lnglat.getLng();
                        that.deviceForm.lat = e.lnglat.getLat();
                    });
                    document.getElementsByClassName("tdt-control-copyright tdt-control")[0].style.display = 'none'
                    this.getToken().then(() => {
                        this.getLand().then(() => {
                            for (const key in this.lands.data) {
                                if (Object.hasOwnProperty.call(this.lands.data, key)) {
                                    const boundary = this.lands.data[key].boundary;
                                    let points = [];
                                    for (const key in boundary) {
                                        if (Object.hasOwnProperty.call(boundary, key)) {
                                            let trans_point = this.trans(boundary[key].longitude, boundary[key].latitude);
                                            points.push(new T.LngLat(trans_point.lng, trans_point.lat));
                                        }
                                    }
                                    let polygon = new T.Polygon(points, { color: "green", weight: 2, opacity: 0.5, fillColor: "#b9dec9", fillOpacity: 0.6 });
                                    map.addOverLay(polygon);
                                    let id = this.lands.data[key].id;
                                    polygon.addEventListener("click", () => {
                                        this.openLandDialog(id);
                                    });
                                }
                            }
                            that.initDeviceData();
                        })
                    });
                    this.$webAxios.post("/tdmap/getDevices")
                        .then(res => {
                            this.deviceList = res.data.data;
                            for (const key in this.deviceList) {
                                if (Object.hasOwnProperty.call(this.deviceList, key)) {
                                    const thisMarker = this.deviceList[key];
                                    let icon = new T.Icon({
                                        iconUrl: require("./" + thisMarker.base_device_type.deviceIcon.replace('//static//local-style//img//icon//', '')),
                                        iconSize: new T.Point(48, 48),
                                        iconAnchor: new T.Point(10, 25)
                                    });
                                    let transCenterPoint = this.trans(thisMarker.deviceLng, thisMarker.deviceLat);
                                    let marker = new T.Marker(new T.LngLat(transCenterPoint.lng, transCenterPoint.lat), { icon: icon });
                                    map.addOverLay(marker);
                                    marker.addEventListener("click", function (e) {
                                        let point = e.lnglat;
                                        let content = "<span style='font-size: 16px;'>" + thisMarker.deviceDescription + "</span>";
                                        marker = new T.Marker(point);
                                        let markerInfoWin = new T.InfoWindow(content, { offset: new T.Point(0, -30) });
                                        map.openInfoWindow(markerInfoWin, point);
                                    });
                                    markers.push({ deviceTypeId: thisMarker.base_device_type.deviceTypeId, marker: marker });
                                    // let flag = false;
                                    // for(let i = 0;i < that.deviceForm.typeList.length;i++){
                                    //     if(that.deviceForm.typeList[i].deviceTypeId == thisMarker.base_device_type.deviceTypeId)
                                    //         flag = true;
                                    // }
                                    // if(!flag)
                                    //     that.deviceForm.typeList.push(thisMarker.base_device_type);
                                }
                            }
                            this.$webAxios.post("/tdmap/getAllTypes")
                                .then(res => {
                                    that.deviceForm.typeList = res.data.data;
                                    let option1 = {};
                                    option1.id = 10000;
                                    option1.label = "设备图层";
                                    let children = [];
                                    for (let i = 0; i < that.deviceForm.typeList.length; i++) {
                                        let tmp = { id: that.deviceForm.typeList[i].deviceTypeId, label: that.deviceForm.typeList[i].deviceType };
                                        children.push(tmp);
                                    }
                                    option1.children = children;
                                    that.toolbarList = [];
                                    that.toolbarList.push(option1);
                                    that.toolbarLoading = false;
                                })
                                .catch(err => {
                                    err;
                                })
                        })
                        .catch(err => {
                            err;
                        })
                    var menu = new T.ContextMenu({ width: 140 });
                    var txtMenuItem = [
                        {
                            text: '设备管理',
                            isDisable: false,
                            callback: function () {
                                that.deviceManagementVisible = true;
                            }
                        }
                    ];
                    for (var i = 0; i < txtMenuItem.length; i++) {
                        var item = new T.MenuItem(txtMenuItem[i].text, txtMenuItem[i].callback);
                        menu.addItem(item);
                    }
                    map.addContextMenu(menu);
                });
            },
            async initDeviceData() {
                let that = this;
                let res = await this.$webAxios.post("/tdmap/getDeviceType");
                this.deviceTypeTable = res.data.data;
                for (const key in this.deviceTypeTable) {
                    if (Object.hasOwnProperty.call(this.deviceTypeTable, key)) {
                        const deviceType = this.deviceTypeTable[key];
                        for (const key2 in deviceType.thisDeviceList) {
                            if (Object.hasOwnProperty.call(deviceType.thisDeviceList, key2)) {
                                const deviceDetail = deviceType.thisDeviceList[key2];
                                let matchedLand = that.lands.data.filter(item => {
                                    return deviceDetail.landPk == item.id;
                                })
                                deviceDetail.landId = matchedLand[0].id;
                                deviceDetail.name = matchedLand[0].name;
                                deviceDetail.area = matchedLand[0].area;
                                deviceDetail.region = matchedLand[0].regionName;
                                deviceDetail.landType = matchedLand[0].typeName;
                                deviceDetail.isMapping = matchedLand[0].isMappingCompletedName;
                            }
                        }
                    }
                }
            },
            async getToken() {
                // let res = await this.$axios.post("/api/Account/Login",{
                //     userName: 'admin',
                //     password: '123456'
                // },{
                //     headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                // })
                this.token = await localStorage.token;
            },
            async getLand() {
                let res = await this.$axios.get("/api/Land/GetLandsByCompany", {

                }, {
                    headers:
                    {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': 'Bearer ' + this.token
                    },
                })
                this.lands = res.data;
            },
            openLandDialog(landid) {
                this.landData = [];
                let selectedLand = this.lands.data.filter(item => {
                    return item.id == landid;
                });
                let selectedDevice = this.deviceList.filter(item => {
                    return item.landPk == landid;
                });
                for (const key in selectedDevice) {
                    if (Object.hasOwnProperty.call(selectedDevice, key)) {
                        const device = selectedDevice[key];
                        let obj = {};
                        obj.landId = selectedLand[0].id;
                        obj.name = selectedLand[0].name;
                        obj.area = selectedLand[0].area;
                        obj.region = selectedLand[0].regionName;
                        obj.landType = selectedLand[0].typeName;
                        obj.isMapping = selectedLand[0].isMappingCompletedName;
                        obj.deviceId = device.deviceId;
                        obj.deviceCode = device.deviceCode;
                        obj.type = device.base_device_type.deviceType;
                        obj.description = device.deviceDescription;
                        this.landData.push(obj);
                    }
                }
                this.dialogVisible = true;
                this.deviceForm.lng = this.clickedLng;
                this.deviceForm.lat = this.clickedLat;
                this.deviceForm.landId = selectedLand[0].id;
                this.deviceForm.land.id = selectedLand[0].id;
                this.deviceForm.land.name = selectedLand[0].name;
                this.deviceForm.land.area = selectedLand[0].area;
                this.deviceForm.land.region = selectedLand[0].regionName;
                this.deviceForm.land.landType = selectedLand[0].typeName;
                this.deviceForm.land.isMapping = selectedLand[0].isMappingCompletedName;
            },
            async deleteLandDevice(scope) {
                let connectionId = scope.row.deviceId;
                let res = await this.$webAxios.post("/tdmap/deleteConnection", { connectionId })
                if (res.data.data) {
                    this.$message.success("删除成功！");
                    this.closeBox();
                    this.initmap();
                }
            },
            editDeviceType(row) {
                this.deviceTypeVisible = true;
                this.deviceTypeForm.name = row.deviceType;
                this.deviceTypeForm.id = row.deviceTypeId;
                this.closeBox();
                this.initmap();
            },
            deleteDeviceType(row) {
                let id = row.deviceTypeId;
                this.$webAxios.post("tdmap/deleteDeviceType", {
                    id
                })
                    .then(res => {
                        if (res.data.data)
                            this.$message.success("删除成功！");
                    })
                    .catch(err => {
                        err;
                        this.$message.error("服务器繁忙，请稍后再试！");
                    })
                this.closeBox();
                this.initmap();
            },
            handleExceed() {
                this.$message.warning('只允许上传一张图片！');
                return;
            },
            beforeRemove(file) {
                return this.$confirm(`确定移除 ${file.name}？`);
            },
            handleSelectFile(file) {
                this.deviceTypeForm.file = file.raw;
            },
            submitUploadIcon() {
                if (this.deviceTypeForm.name == '' || this.deviceTypeForm.file == null) {
                    this.$message.warning('请完善设备类型信息！');
                    return;
                }
                let formdata = new FormData();
                formdata.append("id", this.deviceTypeForm.id);
                formdata.append("name", this.deviceTypeForm.name);
                formdata.append("file", this.deviceTypeForm.file);
                this.$axios.create().post("tdmap/loadupDeviceType", formdata)
                    .then(res => {
                        if (res.data.data)
                            this.$message.success("已完成类型变动！");
                    })
                    .catch(err => {
                        err;
                        this.$message.error("服务器繁忙，请稍后再试！");
                    })
                this.deviceTypeVisible = false;
                this.deviceTypeForm = { id: -1, name: '', file: {} };
                this.closeBox();
                this.initmap();
            },
            submitInsertDevice() {
                this.$webAxios.post('tdmap/insertDevice', this.deviceForm)
                    .then(res => {
                        if (res.data.data) {
                            this.$message.success("添加成功！");
                            this.closeBox();
                            this.initmap();
                        }
                    })
                    .catch(err => {
                        err;
                    });
                this.deviceVisible = false;
            },
            closeBox() {
                this.deviceVisible = false;
                this.dialogVisible = false;
                this.deviceTypeVisible = false;
                this.deviceManagementVisible = false;
            },
            handleMarker(prop1, prop2) {
                prop1;
                for (let i = 0; i < markers.length; i++) {
                    markers[i].marker.hide();
                }
                for (let i = 0; i < prop2.checkedKeys.length; i++) {
                    if (prop2.checkedKeys[i] < 10000) {
                        markers.filter(item => {
                            if (item.deviceTypeId == prop2.checkedKeys[i])
                                item.marker.show();
                        });
                    }
                }

            },
            trans(lng, lat) {
                // 解开注释则为baidumap转tdmap
                // let x_pi = 3.14159265358979324 * 3000.0 / 180.0;
                // let mars_point = { lng: 0, lat: 0 };
                // let x = lng - 0.0065;
                // let y = lat - 0.006;
                // let z = Math.sqrt(x*x + y*y) - 0.00002 * Math.sin(y * x_pi);
                // let theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * x_pi);
                // mars_point.lng = z * Math.cos(theta);
                // mars_point.lat = z * Math.sin(theta);
                let d = this.delta(lng, lat);
                return {
                    "lng": lng - d.lng,
                    "lat": lat - d.lat
                };
            },
            delta(lng, lat) {
                let a = 6378245.0; //  a: 卫星椭球坐标投影到平面地图坐标系的投影因子。
                let ee = 0.00669342162296594323; //  ee: 椭球的偏心率。
                let dLng = this.transformLng(lng - 105.0, lat - 35.0);
                let dLat = this.transformLat(lng - 105.0, lat - 35.0);
                let radLat = lat / 180.0 * this.PI;
                let magic = Math.sin(radLat);
                magic = 1 - ee * magic * magic;
                let sqrtMagic = Math.sqrt(magic);
                dLng = (dLng * 180.0) / (a / sqrtMagic * Math.cos(radLat) * this.PI);
                dLat = (dLat * 180.0) / ((a * (1 - ee)) / (magic * sqrtMagic) * this.PI);
                return {
                    'lng': dLng,
                    'lat': dLat
                }
            },
            transformLng(x, y) {
                let ret = 300.0 + x + 2.0 * y + 0.1 * x * x + 0.1 * x * y + 0.1 * Math.sqrt(Math.abs(x));
                ret += (20.0 * Math.sin(6.0 * x * this.PI) + 20.0 * Math.sin(2.0 * x * this.PI)) * 2.0 / 3.0;
                ret += (20.0 * Math.sin(x * this.PI) + 40.0 * Math.sin(x / 3.0 * this.PI)) * 2.0 / 3.0;
                ret += (150.0 * Math.sin(x / 12.0 * this.PI) + 300.0 * Math.sin(x / 30.0 * this.PI)) * 2.0 / 3.0;
                return ret;
            },
            transformLat(x, y) {
                let ret = -100.0 + 2.0 * x + 3.0 * y + 0.2 * y * y + 0.1 * x * y + 0.2 * Math.sqrt(Math.abs(x));
                ret += (20.0 * Math.sin(6.0 * x * this.PI) + 20.0 * Math.sin(2.0 * x * this.PI)) * 2.0 / 3.0;
                ret += (20.0 * Math.sin(y * this.PI) + 40.0 * Math.sin(y / 3.0 * this.PI)) * 2.0 / 3.0;
                ret += (160.0 * Math.sin(y / 12.0 * this.PI) + 320 * Math.sin(y * this.PI / 30.0)) * 2.0 / 3.0;
                return ret;
            },
        },
    };
</script>
<style>
    #mapDiv {
        height: 100vh;
        width: 100%;
    }

    .tdt-infowindow-content {
        font-size: 16px;
    }

    #mapCard .el-card__body {
        padding: 0px !important;
    }

    #mapCard .el-dialog__body {
        padding: 0 16px !important;
    }

    .demo-table-expand {
        font-size: 0;
    }

    .demo-table-expand label {
        width: 90px;
        color: #99a9bf;
    }

    .demo-table-expand .el-form-item {
        margin-right: 0;
        margin-bottom: 0;
        width: 50%;
    }

    .transition-box {
        position: fixed;
        top: 100px;
        right: 50px;
        z-index: 999;
        width: 175px;
        height: 225px;
        border-radius: 4px;
        background-color: #ffffff;
        box-sizing: border-box;
    }

    .slide-fade-enter-active {
        transition: all .3s ease;
    }

    .slide-fade-leave-active {
        transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }

    .slide-fade-enter,
    .slide-fade-leave-to

    /* .slide-fade-leave-active for below version 2.1.8 */
        {
        transform: translateX(10px);
        opacity: 0;
    }

    .el-tree-node__content {
        padding: 14px 0;
    }

    .el-tree-node__children {
        width: 120%;
    }
</style>